@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  scrollbar-width: thin;          /* "auto" or "thin" */
  scrollbar-color: #1C232B rgb(255, 255, 255);
  font-family: 'Archivo', sans-serif !important;
}

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #686868;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: #434243;
  }
  ::-webkit-scrollbar-track:hover {
    cursor: pointer;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #0b9de6;
    cursor: pointer;
  }

  /* TABLE REFRESH LOADER CSS */
  .three-body {
    --uib-size: 35px;
    --uib-speed: 0.8s;
    --uib-color: #5D3FD3;
    position: relative;
    display: inline-block;
    height: var(--uib-size);
    width: var(--uib-size);
    animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
  }
  
  .three-body__dot {
    position: absolute;
    height: 100%;
    width: 30%;
  }
  
  .three-body__dot:after {
    content: '';
    position: absolute;
    height: 0%;
    width: 100%;
    padding-bottom: 100%;
    background-color: var(--uib-color);
    border-radius: 50%;
  }
  
  .three-body__dot:nth-child(1) {
    bottom: 5%;
    left: 0;
    transform: rotate(60deg);
    transform-origin: 50% 85%;
  }
  
  .three-body__dot:nth-child(1)::after {
    bottom: 0;
    left: 0;
    animation: wobble1 var(--uib-speed) infinite ease-in-out;
    animation-delay: calc(var(--uib-speed) * -0.3);
  }
  
  .three-body__dot:nth-child(2) {
    bottom: 5%;
    right: 0;
    transform: rotate(-60deg);
    transform-origin: 50% 85%;
  }
  
  .three-body__dot:nth-child(2)::after {
    bottom: 0;
    left: 0;
    animation: wobble1 var(--uib-speed) infinite
       calc(var(--uib-speed) * -0.15) ease-in-out;
  }
  
  .three-body__dot:nth-child(3) {
    bottom: -5%;
    left: 0;
    transform: translateX(116.666%);
  }
  
  .three-body__dot:nth-child(3)::after {
    top: 0;
    left: 0;
    animation: wobble2 var(--uib-speed) infinite ease-in-out;
  }
  
  @keyframes spin78236 {
    0% {
    transform: rotate(0deg);
    }
  
    100% {
    transform: rotate(360deg);
    }
  }
  
  @keyframes wobble1 {
    0%,
    100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
    }
  
    50% {
    transform: translateY(-66%) scale(0.65);
    opacity: 0.8;
    }
  }
  
  @keyframes wobble2 {
    0%,
    100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
    }
  
    50% {
    transform: translateY(66%) scale(0.65);
    opacity: 0.8;
    }
  }

/* NEXUS PAGE LOADER */
  
/* From Uiverse.io by Sourcesketch */ 
.container {
  width: 400px;
  height: 400px;
  position: relative;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  margin: auto;
  filter: url("#goo");
  animation: rotate-move 2s ease-in-out infinite;
}

.dot {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.dot-3 {
  background-color: #44BCFF;
  animation: dot-3-move 2s ease infinite, index 6s ease infinite;
}

.dot-2 {
  background-color: #FF44EC;
  animation: dot-2-move 2s ease infinite, index 6s -4s ease infinite;
}

.dot-1 {
  background-color: #FF675E;
  animation: dot-1-move 2s ease infinite, index 6s -2s ease infinite;
}

@keyframes dot-3-move {
  20% {
    transform: scale(1);
  }
  45% {
    transform: translateY(-18px) scale(0.45);
  }
  60% {
    transform: translateY(-90px) scale(0.45);
  }
  80% {
    transform: translateY(-90px) scale(0.45);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
}

@keyframes dot-2-move {
  20% {
    transform: scale(1);
  }
  45% {
    transform: translate(-16px, 12px) scale(0.45);
  }
  60% {
    transform: translate(-80px, 60px) scale(0.45);
  }
  80% {
    transform: translate(-80px, 60px) scale(0.45);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
}

@keyframes dot-1-move {
  20% {
    transform: scale(1);
  }
  45% {
    transform: translate(16px, 12px) scale(0.45);
  }
  60% {
    transform: translate(80px, 60px) scale(0.45);
  }
  80% {
    transform: translate(80px, 60px) scale(0.45);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
}

@keyframes rotate-move {
  55% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  80% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes index {
  0%,
  100% {
    z-index: 3;
  }
  33.3% {
    z-index: 2;
  }
  66.6% {
    z-index: 1;
  }
}